import { FC, useEffect } from "react";
import {
	Drawer,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	DrawerHeader,
	DrawerBody,
	useDisclosure,
	IconButton,
	Box,
	BoxProps,
	Image,
	Container,
	Link,
	VStack,
	useMediaQuery,
	useTheme,
	Button,
} from "@chakra-ui/react";
import { ArrowBackIcon, HamburgerIcon } from "@chakra-ui/icons";
import { Link as RouterLink, NavLink } from "react-router-dom";
import NavButton from "./NavButton";
import { navRoutes } from "../routes";
import { useAppDispatch } from "../utils/hooks";
import { handleLogout } from "../redux/slices/auth.slice";
// image
import logo from "../images/baity-logo.png";
import { SquaresIcon } from "./Icons";

type SidebarProps = {
	expand?: "lg" | "md" | "sm";
	vrWidth?: string;
};
const Sidebar: FC<SidebarProps> = ({ expand = "md", vrWidth = "19em" }) => {
	const { breakpoints } = useTheme();
	const [isGtExpand] = useMediaQuery(`(min-width: ${breakpoints[expand]})`);
	const { isOpen, onClose, onOpen } = useDisclosure();

	useEffect(() => {
		// force hiding the drawer in big screens
		if (isGtExpand) onClose();
	}, [isGtExpand]); // eslint-disable-line

	return (
		<Box
			as="aside"
			w={{ [expand]: vrWidth }}
			h={{ [expand]: "100vh" }}
			flex="0 0 auto"
		>
			<Box
				h="full"
				w={{ [expand]: vrWidth }}
				pos={{ [expand]: "fixed" }}
				top="0"
				overflow="hidden auto"
				boxShadow="lg"
				bgColor="white"
			>
				<Box
					as="header"
					py="3"
					mb={{ [expand]: "4" }}
					boxShadow={{ [expand]: "lg" }}
				>
					<Container
						d="flex"
						justifyContent={{ base: "space-between", [expand]: "center" }}
						alignItems="center"
					>
						<Link as={RouterLink} to="/" h="56px">
							<Image src={logo} alt="Baity Logo" height="100%" />
						</Link>
						<IconButton
							onClick={onOpen}
							icon={<HamburgerIcon boxSize="7" />}
							aria-label="menu"
							variant="ghost"
							d={{ [expand]: "none" }}
							me="3"
						/>
					</Container>
				</Box>
				<SidebarContent d={{ base: "none", [expand]: "block" }} px="6" />
				<Drawer isOpen={isOpen} onClose={onClose} placement="right">
					<DrawerOverlay />
					<DrawerContent>
						<DrawerCloseButton d={{ sm: "none" }} />
						<DrawerHeader d={{ sm: "none" }}>لوحة التحكم</DrawerHeader>
						<DrawerBody mt={{ base: "none", sm: "2" }}>
							<SidebarContent />
						</DrawerBody>
					</DrawerContent>
				</Drawer>
			</Box>
		</Box>
	);
};

const SidebarContent: FC<BoxProps> = (props) => {
	const dispatch = useAppDispatch();
	function onLogout() {
		dispatch(handleLogout());
	}
	return (
		<Box {...props}>
			<VStack as="ul" spacing="2" alignItems="stretch" listStyleType="none">
				{navRoutes.map((route, i) => (
					<li key={i}>
						<NavButton
							as={NavLink}
							{...route}
							leftIcon={<SquaresIcon />}
							w="full"
							_after={{
								content: `""`,
								pos: "absolute",
								h: "1px",
								w: "88%",
								bottom: "-4px",
								left: "50%",
								transform: "translateX(-50%)",
								bgColor: "gray.50",
							}}
						/>
					</li>
				))}
			</VStack>
			<Button
				variant="link"
				rightIcon={<ArrowBackIcon />}
				onClick={onLogout}
				w="full"
				mt="6"
				mb="3"
				color="gray.400"
			>
				تسجيل الخروج
			</Button>
		</Box>
	);
};

export default Sidebar;
