import {
	createAsyncThunk,
	createSlice,
	isFulfilled,
	isPending,
	createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "..";
import { fetchPage, show, add, update, del } from "../../apis/committees.api";
import { sortedIndex } from "../../utils/helpers";
import { isRejectedAction } from "../../utils/redux";

export const handleLoadCommittees = createAsyncThunk(
	"committees/all",
	fetchPage,
	{
		condition: (page, { getState }) =>
			getState().committees.currentPage !== page,
	}
);

export const handleShowCommittee = createAsyncThunk("committees/show", show, {
	condition: (id, { getState }) => !getState().entities.committees[id],
});

export const handleDelCommittee = createAsyncThunk(
	"committees/del",
	(item: Committee) => del(item.id)
);

export const handleAddCommittee = createAsyncThunk("committees/add", add);
export const handleUpdateCommittee = createAsyncThunk(
	"committees/update",
	update
);

const slice = createSlice({
	name: "committees",
	initialState: {
		total: 0,
		pagesCount: 1,
		currentPage: null as number | null,
		status: "idle" as LoadingStatus,
		ids: [] as number[],
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoadCommittees.pending, (state) => {
				state.status = "pending";
				state.ids = []; // rest ids;
			})
			.addCase(handleLoadCommittees.fulfilled, (state, { payload }) => {
				state.status = "succeeded";
				state.total = payload.result.meta.total;
				state.pagesCount = payload.result.meta.last_page;
				state.currentPage = payload.result.meta.current_page;
				state.ids = payload.result.data;
			})
			.addCase(handleAddCommittee.fulfilled, (state, { payload }) => {
				state.ids.unshift(payload.result.data);
				state.total += 1;
			})
			.addCase(handleDelCommittee.pending, (state, { meta: { arg } }) => {
				state.ids.splice(state.ids.indexOf(arg.id), 1); // remove target item from ids array
				state.total -= 1;
			})
			.addCase(handleDelCommittee.rejected, (state, { meta: { arg } }) => {
				state.ids.splice(sortedIndex(state.ids, arg.id), 0, arg.id); // put target back to ids array (preserve sorting)
				state.total += 1;
			})
			.addCase(handleShowCommittee.pending, (state) => {
				state.status = "pendingOne";
			})
			.addMatcher(
				isFulfilled(
					handleAddCommittee,
					handleUpdateCommittee,
					handleShowCommittee
				),
				(state) => {
					state.status = "succeeded";
				}
			)
			.addMatcher(
				isPending(handleAddCommittee, handleUpdateCommittee),
				(state) => {
					state.status = "mutating";
				}
			)
			.addMatcher(isRejectedAction("committees/"), (state) => {
				state.status = "failed";
			});
	},
});

export const selectAllCommittees = createSelector(
	(state: RootState) => state.entities.committees,
	(entity) => Object.values(entity)
);

export const selectCommittees = createSelector(
	(state: RootState) => state.entities.committees,
	(state: RootState) => state.committees.ids,
	(entity, ids) => ids.map((id) => entity[id])
);

export default slice.reducer;
