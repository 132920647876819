import { schema } from "normalizr";

export const permEntity = new schema.Entity("permissions");
export const roleEntity = new schema.Entity("roles", {
	permissions: [permEntity],
});
export const adminEntity = new schema.Entity("admins", {
	roles: [roleEntity],
});
export const newsEntity = new schema.Entity("news");
export const partnerEntity = new schema.Entity("partners");
export const govEntity = new schema.Entity("governments");
export const invoicesEntity = new schema.Entity("invoices");
export const villageEntity = new schema.Entity("villages", {
	government: govEntity,
});
export const beneEntity = new schema.Entity("beneficiaries", {
	village: villageEntity,
});
export const committeeEntity = new schema.Entity("committees");
export const commMemberEntity = new schema.Entity("commMembers", {
	committee: committeeEntity,
});
export const membershipCatEntity = new schema.Entity("membershipCats");
export const membershipEntity = new schema.Entity("memberships", {
	committees: [committeeEntity],
});
export const volunteerEntity = new schema.Entity("volunteers");
export const directorEntity = new schema.Entity("directors");
export const jobEntity = new schema.Entity("jobs");
export const jobAppEntity = new schema.Entity("jobApps");
export const messageEntity = new schema.Entity("messages");
export const initiativeEntity = new schema.Entity("initiatives");
export const projectEntity = new schema.Entity("projects");
export const reportEntity = new schema.Entity("reports");
export const goalEntity = new schema.Entity("goals");
export const principleEntity = new schema.Entity("principles");
export const govFileTypeEntity = new schema.Entity("govFileTypes");
export const govFileEntity = new schema.Entity("govFiles", {
	governance_file_type: govFileTypeEntity,
});
