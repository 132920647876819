import {
	createAsyncThunk,
	createSlice,
	isFulfilled,
	isPending,
	createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "..";
import {
	fetchPage,
	add,
	update,
	del,
	updateStatus,
} from "../../apis/memberships.api";
import { sortedIndex } from "../../utils/helpers";
import { isRejectedAction } from "../../utils/redux";

export const handleLoadMemberships = createAsyncThunk(
	"memberships/all",
	fetchPage,
	{
		condition: ({ page, status }, { getState }) => {
			const { currentPage, filterBy } = getState().memberships;
			return currentPage !== page || filterBy !== status;
		},
	}
);

export const handleDelMembership = createAsyncThunk(
	"memberships/del",
	(item: Membership) => del(item.id)
);

export const handleAddMembership = createAsyncThunk("memberships/add", add);
export const handleUpdateMembership = createAsyncThunk(
	"memberships/update",
	update
);
export const handleUpdateMembershipStatus = createAsyncThunk(
	"memberships/update-status",
	updateStatus
);

const slice = createSlice({
	name: "memberships",
	initialState: {
		total: 0,
		pagesCount: 1,
		currentPage: null as number | null,
		filterBy: undefined as number | undefined,
		status: "idle" as LoadingStatus,
		ids: [] as number[],
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoadMemberships.pending, (state) => {
				state.status = "pending";
				state.ids = []; // rest data
			})
			.addCase(handleLoadMemberships.fulfilled, (state, { payload, meta }) => {
				state.status = "succeeded";
				state.currentPage = meta.arg.page;
				state.filterBy = meta.arg.status;
				state.total = payload.result.meta.total;
				state.pagesCount = payload.result.meta.last_page;
				state.ids = payload.result.data;
			})
			.addCase(handleAddMembership.fulfilled, (state, { payload }) => {
				state.ids.unshift(payload.result.data);
				state.total += 1;
			})
			.addCase(handleDelMembership.pending, (state, { meta: { arg } }) => {
				state.ids.splice(state.ids.indexOf(arg.id), 1); // remove target item from ids array
				state.total -= 1;
			})
			.addCase(handleDelMembership.rejected, (state, { meta: { arg } }) => {
				state.ids.splice(sortedIndex(state.ids, arg.id), 0, arg.id); // put target back to ids array (preserve sorting)
				state.total += 1;
			})
			.addMatcher(
				isFulfilled(handleAddMembership, handleUpdateMembership),
				(state) => {
					state.status = "succeeded";
				}
			)
			.addMatcher(
				isPending(handleAddMembership, handleUpdateMembership),
				(state) => {
					state.status = "mutating";
				}
			)
			.addMatcher(isRejectedAction("memberships/"), (state) => {
				state.status = "failed";
			});
	},
});

export const selectMemberships = createSelector(
	(state: RootState) => state.entities.memberships,
	(state: RootState) => state.memberships.ids,
	(entity, ids) => ids.map((id) => entity[id])
);

export default slice.reducer;
