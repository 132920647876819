import {
	createAsyncThunk,
	createSlice,
	isFulfilled,
	isPending,
	createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "..";
import { fetchPage, add, update, del } from "../../apis/beneficiaries.api";
import { sortedIndex } from "../../utils/helpers";
import { isRejectedAction } from "../../utils/redux";

export const handleLoadBenes = createAsyncThunk(
	"beneficiaries/all",
	fetchPage,
	{
		condition: (page, { getState }) =>
			getState().beneficiaries.currentPage !== page,
	}
);

export const handleDelBene = createAsyncThunk(
	"beneficiaries/del",
	(item: Beneficiary) => del(item.id)
);

export const handleAddBene = createAsyncThunk("beneficiaries/add", add);
export const handleUpdateBene = createAsyncThunk(
	"beneficiaries/update",
	update
);

const slice = createSlice({
	name: "beneficiaries",
	initialState: {
		total: 0,
		pagesCount: 1,
		currentPage: null as number | null,
		status: "idle" as LoadingStatus,
		ids: [] as number[],
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoadBenes.pending, (state) => {
				state.status = "pending";
				state.ids = []; // rest ids
			})
			.addCase(handleLoadBenes.fulfilled, (state, { payload }) => {
				state.status = "succeeded";
				state.total = payload.result.meta.total;
				state.pagesCount = payload.result.meta.last_page;
				state.currentPage = payload.result.meta.current_page;
				state.ids = payload.result.data;
			})
			.addCase(handleAddBene.fulfilled, (state, { payload }) => {
				state.ids.unshift(payload.result.data);
				state.total += 1;
			})
			.addCase(handleDelBene.pending, (state, { meta: { arg } }) => {
				state.ids.splice(state.ids.indexOf(arg.id), 1); // remove target item from ids array
				state.total -= 1;
			})
			.addCase(handleDelBene.rejected, (state, { meta: { arg } }) => {
				state.ids.splice(sortedIndex(state.ids, arg.id), 0, arg.id); // put target back to ids array (preserve sorting)
				state.total += 1;
			})
			.addMatcher(isFulfilled(handleAddBene, handleUpdateBene), (state) => {
				state.status = "succeeded";
			})
			.addMatcher(isPending(handleAddBene, handleUpdateBene), (state) => {
				state.status = "mutating";
			})
			.addMatcher(isRejectedAction("beneficiaries/"), (state) => {
				state.status = "failed";
			});
	},
});

export const selectBeneficiaries = createSelector(
	(state: RootState) => state.entities.beneficiaries,
	(state: RootState) => state.beneficiaries.ids,
	(entity, ids) => ids.map((id) => entity[id])
);

export default slice.reducer;
