import {
	createAsyncThunk,
	createSlice,
	isFulfilled,
	isPending,
	createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "..";
import { fetchPage, show, add, update, del } from "../../apis/admins.api";
import { sortedIndex } from "../../utils/helpers";
import { isRejectedAction } from "../../utils/redux";

export const handleLoadAdmins = createAsyncThunk("admins/all", fetchPage, {
	condition: (page, { getState }) => getState().admins.currentPage !== page,
});

export const handleShowAdmin = createAsyncThunk("admins/show", show, {
	condition: (id, { getState }) => !getState().entities.admins[id]?.roles,
});

export const handleDelAdmin = createAsyncThunk("admins/del", (item: Admin) =>
	del(item.id)
);

export const handleAddAdmin = createAsyncThunk("admins/add", add);
export const handleUpdateAdmin = createAsyncThunk("admins/update", update);

const slice = createSlice({
	name: "admins",
	initialState: {
		total: 0,
		pagesCount: 1,
		currentPage: null as number | null,
		ids: [] as number[],
		status: "idle" as LoadingStatus,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoadAdmins.pending, (state) => {
				state.status = "pending";
				state.ids = []; // reset ids;
			})
			.addCase(handleLoadAdmins.fulfilled, (state, { payload }) => {
				state.status = "succeeded";
				state.total = payload.result.meta.total;
				state.pagesCount = payload.result.meta.last_page;
				state.currentPage = payload.result.meta.current_page;
				state.ids = payload.result.data;
			})
			.addCase(handleShowAdmin.pending, (state) => {
				state.status = "pendingOne";
			})
			.addCase(handleAddAdmin.fulfilled, (state, { payload }) => {
				state.ids.unshift(payload.result.data);
				state.total += 1;
			})
			.addCase(handleDelAdmin.pending, (state, { meta: { arg } }) => {
				state.ids.splice(state.ids.indexOf(arg.id), 1); // remove target item from ids array
				state.total -= 1;
			})
			.addCase(handleDelAdmin.rejected, (state, { meta: { arg } }) => {
				state.ids.splice(sortedIndex(state.ids, arg.id), 0, arg.id); // put target back to ids array (preserve sorting)
				state.total += 1;
			})
			.addMatcher(
				isFulfilled(handleShowAdmin, handleAddAdmin, handleUpdateAdmin),
				(state) => {
					state.status = "succeeded";
				}
			)
			.addMatcher(isPending(handleAddAdmin, handleUpdateAdmin), (state) => {
				state.status = "mutating";
			})
			.addMatcher(isRejectedAction("admins/"), (state) => {
				state.status = "failed";
			});
	},
});

export const selectAdmins = createSelector(
	(state: RootState) => state.entities.admins,
	(state: RootState) => state.admins.ids,
	(entity, ids) => ids.map((id) => entity[id])
);

export default slice.reducer;
