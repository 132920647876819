import { normalize } from "normalizr";
import { partnerEntity } from "./schemas";
import { client } from ".";

// defining normalize return type manually as normalize has bad typescript support
// general types used here are defined in ./src/types.d.ts file
type Normalized = {
	entities: { partners: Record<number, Partner> };
	result: { data: number };
};
type NormalizedList = {
	entities: { partners?: Record<number, Partner> };
	result: {
		data: number[];
		meta: Meta;
	};
};

export async function fetchPage(page: number) {
	const { data } = await client.get(`/partners?page=${page}`);
	return normalize(data, { data: [partnerEntity] }) as NormalizedList;
}

export async function show(id: number) {
	const { data } = await client.get(`/partners/${id}`);
	return normalize(data, { data: partnerEntity }) as Normalized;
}

export async function add(arg: StorePartner) {
	const { data } = await client.post("/partners", arg);
	return normalize(data, { data: partnerEntity }) as Normalized;
}

type UpdateArg = { id: number, item: StorePartner };
export async function update({ id, item }: UpdateArg) {
	const { data } = await client.put(`/partners/${id}`, item);
	return normalize(data, { data: partnerEntity }) as Normalized;
}

export async function del(id: number) {
	const { data } = await client.delete(`/partners/${id}`);
	return data;
}
