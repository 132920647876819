import { client } from ".";

export async function show() {
	const { data } = await client.get<{ data: SettingsData }>("/settings");
	return data;
}

export async function update(settings: Partial<SettingsData>) {
	const { data } = await client.put<{ data: SettingsData }>(
		"/settings/1",
		settings
	);
	return data;
}
