import { FC, Suspense } from "react";
import { Box, Container, Spinner, Heading } from "@chakra-ui/react";
import { Switch, Route, Redirect } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import ErrorBoundary from "./components/ErrorBoundary";
import routes from "./routes";

const Dashboard: FC = () => {
	return (
		<Box
			d={{ lg: "flex" }}
			pos="relative"
			_after={{
				content: `""`,
				pos: "absolute",
				top: 0,
				w: "full",
				h: "4px",
				bgColor: "brown.500",
			}}
		>
			<Sidebar expand="lg" />
			<Box as="main" flex="1 1 auto" overflow="hidden">
				<Suspense
					fallback={
						<Box className="page" d="flex" justifyContent="center">
							<Spinner
								size="xl"
								thickness="4px"
								speed="0.65s"
								mt="8"
								emptyColor="gray.200"
								color="brand.500"
							/>
						</Box>
					}
				>
					<Switch>
						{routes.map(({ Component, ...rest }, i) => (
							<Route {...rest} key={i}>
								<ErrorBoundary>
									<Component />
								</ErrorBoundary>
							</Route>
						))}
						<Route path="/404">
							<Container>
								<Heading textAlign="center" py="5">
									لا يوجد محتوى
								</Heading>
							</Container>
						</Route>
						<Redirect from="/" to="/beneficiaries" exact />
						{/* <Redirect to="/404" /> */}
					</Switch>
				</Suspense>
			</Box>
		</Box>
	);
};

export default Dashboard;
