import {
	createAsyncThunk,
	createSlice,
	createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "..";
import { fetchPage, del } from "../../apis/messages.api";
import { sortedIndex } from "../../utils/helpers";
import { isRejectedAction } from "../../utils/redux";

export const handleLoadMessages = createAsyncThunk("messages/all", fetchPage, {
	condition: (page, { getState }) => getState().messages.currentPage !== page,
});

export const handleDelMessage = createAsyncThunk(
	"messages/del",
	(item: Message) => del(item.id)
);

const slice = createSlice({
	name: "messages",
	initialState: {
		total: 0,
		pagesCount: 1,
		currentPage: null as number | null,
		status: "idle" as LoadingStatus,
		ids: [] as number[],
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoadMessages.pending, (state) => {
				state.status = "pending";
				state.ids = []; // rest ids
			})
			.addCase(handleLoadMessages.fulfilled, (state, { payload }) => {
				state.status = "succeeded";
				state.total = payload.result.meta.total;
				state.pagesCount = payload.result.meta.last_page;
				state.currentPage = payload.result.meta.current_page;
				state.ids = payload.result.data;
			})
			.addCase(handleDelMessage.pending, (state, { meta: { arg } }) => {
				state.ids.splice(state.ids.indexOf(arg.id), 1); // remove target item from ids array
				state.total -= 1;
			})
			.addCase(handleDelMessage.rejected, (state, { meta: { arg } }) => {
				state.ids.splice(sortedIndex(state.ids, arg.id), 0, arg.id); // put target back to ids array (preserve sorting)
				state.total += 1;
			})
			.addMatcher(isRejectedAction("messages/"), (state) => {
				state.status = "failed";
			});
	},
});

export const selectMessages = createSelector(
	(state: RootState) => state.entities.messages,
	(state: RootState) => state.messages.ids,
	(entity, ids) => ids.map((id) => entity[id])
);

export default slice.reducer;
