import { normalize } from "normalizr";
import { projectEntity } from "./schemas";
import { client } from ".";

// defining normalize return type manually as normalize has bad typescript support
// general types used here are defined in ./src/types.d.ts file
type Normalized = {
	entities: { projects: Record<number, Project> };
	result: { data: number };
};
type NormalizedList = {
	entities: { projects?: Record<number, Project> };
	result: {
		data: number[];
		meta: Meta;
	};
};

export async function fetchPage(page: number) {
	const { data } = await client.get(`/projects?page=${page}`);
	return normalize(data, { data: [projectEntity] }) as NormalizedList;
}

export async function show(id: number) {
	const { data } = await client.get(`/projects/${id}`);
	return normalize(data, { data: projectEntity }) as Normalized;
}

export async function add(arg: StoreProject) {
	const { data } = await client.post("/projects", arg);
	return normalize(data, { data: projectEntity }) as Normalized;
}

type UpdateArg = { id: number; item: StoreProject };
export async function update({ id, item }: UpdateArg) {
	const { data } = await client.put(`/projects/${id}`, item);
	return normalize(data, { data: projectEntity }) as Normalized;
}

export async function del(id: number) {
	const { data } = await client.delete(`/projects/${id}`);
	return data;
}

export async function delGalleryImg(id: number) {
	const { data } = await client.delete(`/project-images/${id}`);
	return data;
}
