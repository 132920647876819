import { Button, ButtonProps, Badge, Text, forwardRef } from "@chakra-ui/react";

type NavButtonProps = ButtonProps & {
	count?: number;
};
const NavButton = forwardRef<NavButtonProps, "button">(
	({ children, count, ...rest }, ref) => {
		return (
			<Button
				variant="ghost"
				justifyContent="start"
				borderRadius="full"
				fontWeight="bold"
				color="gray.400"
				_hover={{
					color: "gray.600",
				}}
				sx={{
					":not(.active, [data-active]) .chakra-icon": { color: "golden.500" },
					"&:hover, &.active, &[data-active]": { bgColor: "gray.50" },
					"&.active, &[data-active]": { color: "brand.500" },
				}}
				{...rest}
			>
				<Text as="span" pe={count && "1"}>
					{children}
				</Text>
				<Badge variant="pill" me="-2.5" ms="auto" color="red.400">
					{count}
				</Badge>
			</Button>
		);
	}
);

export default NavButton;
