import { FC, useMemo } from "react";
import { Box, Button, IconButton } from "@chakra-ui/react";
import { ArrowForwardIcon, ArrowBackIcon } from "@chakra-ui/icons";
import range from "lodash/range";
import { Link, useHistory } from "react-router-dom";
import { useCurrentPage, useAddQuery, usePagination } from "../utils/hooks";

type PaginationProps = { count: number; colorScheme?: string };
const Pagination: FC<PaginationProps> = ({ count, colorScheme }) => {
	const currPage = useCurrentPage();
	const addQuery = useAddQuery();
	const { nextPage, prevPage, pages } = usePagination(count);

	return (
		<Box as="nav" aria-label="Pagination">
			<Box
				as="ul"
				textAlign="end"
				sx={{
					li: {
						d: "inline-block",
						"&:not(:first-of-type)": {
							ms: "2",
						},
					},
				}}
			>
				<li>
					<IconButton
						aria-label="السابق"
						size="sm"
						colorScheme={colorScheme}
						icon={<ArrowForwardIcon />}
						onClick={nextPage}
						disabled={currPage === pages[0]}
					/>
				</li>
				{pages.map((page) => (
					<li key={page}>
						<Button
							size="sm"
							isActive={page === currPage}
							as={Link}
							colorScheme={colorScheme}
							to={{ search: addQuery({ page }) }}
						>
							{page}
						</Button>
					</li>
				))}
				<li>
					<IconButton
						aria-label="التالي"
						size="sm"
						colorScheme={colorScheme}
						icon={<ArrowBackIcon />}
						onClick={prevPage}
						disabled={currPage === pages[pages.length - 1]}
					/>
				</li>
			</Box>
		</Box>
	);
};

export default Pagination;
