import { FC } from "react";
import { Flex, Container, HStack, Image } from "@chakra-ui/react";
import { Redirect, useLocation } from "react-router";
import { useAppSelector } from "../utils/hooks";
import LoginForm from "../components/LoginForm";
// images
import bg from "../images/login-bg.jpg";
import housing from "../images/housing-association.png";

const Login: FC = () => {
	const { state } = useLocation<any>();
	const token = useAppSelector((state) => state.auth.token);

	if (token !== null) {
		return <Redirect to={state?.from ? state.from : "/"} />;
	}

	return (
		<Flex
			align="center"
			minH="100vh"
			py={{ base: "10", lg: "14" }}
			bg={`url(${bg}) center / cover`}
		>
			<HStack
				as={Container}
				justify="space-between"
				align="start"
				spacing={{ base: 0, lg: 8 }}
				sx={{
					"> :last-child": {
						flexBasis: { base: "100%", lg: "60%" },
					},
				}}
			>
				<Image
					htmlWidth="524"
					htmlHeight="531"
					src={housing}
					alt="Developmental Housing Association in Riyadh"
					maxW={{ base: "380px", xl: "420px" }}
					d={{ base: "none", lg: "block" }}
					mt="14"
				/>
				<LoginForm />
			</HStack>
		</Flex>
	);
};

export default Login;
