import { ComponentStyleConfig } from "@chakra-ui/react";

const Input: ComponentStyleConfig = {
	sizes: {
		lg: { field: { fontSize: "md" } },
	},
	variants: {
		outline: {
			field: { borderRadius: 0 },
		},
	},
	defaultProps: { size: "lg", },
};

export default Input;
