import { normalize } from "normalizr";
import { reportEntity } from "./schemas";
import { client } from ".";

// defining normalize return type manually as normalize has bad typescript support
// general types used here are defined in ./src/types.d.ts file
type Normalized = {
	entities: { reports: Record<number, Report> };
	result: { data: number };
};
type NormalizedList = {
	entities: { reports?: Record<number, Report> };
	result: {
		data: number[];
		meta: Meta;
	};
};

export async function fetchPage(page: number) {
	const { data } = await client.get(`/report-files?page=${page}`);
	return normalize(data, { data: [reportEntity] }) as NormalizedList;
}

export async function show(id: number) {
	const { data } = await client.get(`/report-files/${id}`);
	return normalize(data, { data: reportEntity }) as Normalized;
}

export async function add(arg: StoreReport) {
	const { data } = await client.post("/report-files", arg);
	return normalize(data, { data: reportEntity }) as Normalized;
}

type UpdateArg = { id: number, item: StoreReport };
export async function update({ id, item }: UpdateArg) {
	const { data } = await client.put(`/report-files/${id}`, item);
	return normalize(data, { data: reportEntity }) as Normalized;
}

export async function del(id: number) {
	const { data } = await client.delete(`/report-files/${id}`);
	return data;
}
