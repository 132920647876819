import {
	createAsyncThunk,
	createSlice,
	isFulfilled,
	isPending,
	createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "..";
import { fetchPage, add, update, del } from "../../apis/commMembers.api";
import { sortedIndex } from "../../utils/helpers";
import { isRejectedAction } from "../../utils/redux";

export const handleLoadCommMembers = createAsyncThunk("commMembers/all", fetchPage, {
	condition: (arg, { getState }) => {
		const { currentPage, filterBy } = getState().commMembers;
		return currentPage !== arg.page || filterBy !== arg.committee_id;
	},
});

export const handleDelCommMember = createAsyncThunk(
	"commMembers/del",
	(item: CommMember) => del(item.id)
);

export const handleAddCommMember = createAsyncThunk("commMembers/add", add);
export const handleUpdateCommMember = createAsyncThunk("commMembers/update", update);

const slice = createSlice({
	name: "commMembers",
	initialState: {
		total: 0,
		pagesCount: 1,
		currentPage: null as number | null,
		filterBy: undefined as number | undefined,
		status: "idle" as LoadingStatus,
		ids: [] as number[],
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoadCommMembers.pending, (state) => {
				state.status = "pending";
				state.ids = []; // rest ids
			})
			.addCase(handleLoadCommMembers.fulfilled, (state, { payload, meta }) => {
				state.status = "succeeded";
				state.total = payload.result.meta.total;
				state.pagesCount = payload.result.meta.last_page;
				state.currentPage = payload.result.meta.current_page;
				state.filterBy = meta.arg.committee_id;
				state.ids = payload.result.data;
			})
			.addCase(handleAddCommMember.fulfilled, (state, { payload }) => {
				state.ids.unshift(payload.result.data);
				state.total += 1;
			})
			.addCase(handleDelCommMember.pending, (state, { meta: { arg } }) => {
				state.ids.splice(state.ids.indexOf(arg.id), 1); // remove target item from ids array
				state.total -= 1;
			})
			.addCase(handleDelCommMember.rejected, (state, { meta: { arg } }) => {
				state.ids.splice(sortedIndex(state.ids, arg.id), 0, arg.id); // put target back to ids array (preserve sorting)
				state.total += 1;
			})
			.addMatcher(
				isFulfilled(handleAddCommMember, handleUpdateCommMember),
				(state) => {
					state.status = "succeeded";
				}
			)
			.addMatcher(isPending(handleAddCommMember, handleUpdateCommMember), (state) => {
				state.status = "mutating";
			})
			.addMatcher(isRejectedAction("commMembers/"), (state) => {
				state.status = "failed";
			});
	},
});

export const selectCommMembers = createSelector(
	(state: RootState) => state.entities.commMembers,
	(state: RootState) => state.commMembers.ids,
	(entity, ids) => ids.map((id) => entity[id])
);

export default slice.reducer;
