import { normalize } from "normalizr";
import { jobAppEntity } from "./schemas";
import { client } from ".";

// defining normalize return type manually as normalize has bad typescript support
// general types used here are defined in ./src/types.d.ts file
type Entities = {
	jobApps: Record<number, JobApp>;
	jobs: Record<number, Job>;
};
type Normalized = {
	entities: Entities;
	result: { data: number };
};
type NormalizedList = {
	entities: Partial<Entities>;
	result: {
		data: number[];
		meta: Meta;
	};
};

type AllArg = { page: number; job_id: number };
export async function fetchPage({ page, job_id }: AllArg) {
	const { data } = await client.get("/job-applications", {
		params: { page, job_id },
	});
	return normalize(data, { data: [jobAppEntity] }) as NormalizedList;
}

export async function show(id: number) {
	const { data } = await client.get(`/job-applications/${id}`);
	return normalize(data, { data: jobAppEntity }) as Normalized;
}

export async function del(id: number) {
	const { data } = await client.delete(`/job-applications/${id}`);
	return data;
}
