import {
	createAsyncThunk,
	createSlice,
	isFulfilled,
	isPending,
	createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "..";
import { fetchPage, add, update, del } from "../../apis/govFiles.api";
import { sortedIndex } from "../../utils/helpers";
import { isRejectedAction } from "../../utils/redux";

export const handleLoadGovFiles = createAsyncThunk("govFiles/all", fetchPage, {
	condition: (page, { getState }) => getState().govFiles.currentPage !== page,
});

export const handleDelGovFile = createAsyncThunk(
	"govFiles/del",
	(item: GovFile) => del(item.id)
);

export const handleAddGovFile = createAsyncThunk("govFiles/add", add);
export const handleUpdateGovFile = createAsyncThunk("govFiles/update", update);

const slice = createSlice({
	name: "govFiles",
	initialState: {
		total: 0,
		pagesCount: 1,
		currentPage: null as number | null,
		status: "idle" as LoadingStatus,
		ids: [] as number[],
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoadGovFiles.pending, (state) => {
				state.status = "pending";
				state.ids = []; // rest ids
			})
			.addCase(handleLoadGovFiles.fulfilled, (state, { payload }) => {
				state.status = "succeeded";
				state.total = payload.result.meta.total;
				state.pagesCount = payload.result.meta.last_page;
				state.currentPage = payload.result.meta.current_page;
				state.ids = payload.result.data;
			})
			.addCase(handleAddGovFile.fulfilled, (state, { payload }) => {
				state.ids.unshift(payload.result.data);
				state.total += 1;
			})
			.addCase(handleDelGovFile.pending, (state, { meta: { arg } }) => {
				state.ids.splice(state.ids.indexOf(arg.id), 1); // remove target item from ids array
				state.total -= 1;
			})
			.addCase(handleDelGovFile.rejected, (state, { meta: { arg } }) => {
				state.ids.splice(sortedIndex(state.ids, arg.id), 0, arg.id); // put target back to ids array (preserve sorting)
				state.total += 1;
			})
			.addMatcher(
				isFulfilled(handleAddGovFile, handleUpdateGovFile),
				(state) => {
					state.status = "succeeded";
				}
			)
			.addMatcher(isPending(handleAddGovFile, handleUpdateGovFile), (state) => {
				state.status = "mutating";
			})
			.addMatcher(isRejectedAction("govFiles/"), (state) => {
				state.status = "failed";
			});
	},
});

export const selectGovFiles = createSelector(
	(state: RootState) => state.entities.govFiles,
	(state: RootState) => state.govFiles.ids,
	(entity, ids) => ids.map((id) => entity[id])
);

export default slice.reducer;
