import { client } from ".";

type AuthRes = { data: Admin; token: string; is_super_admin: boolean };

export async function login(loginData: LoginData) {
	const { data } = await client.post<AuthRes>("/login", loginData);
	localStorage.token = data.token;
	localStorage.isSuperAdmin = data.is_super_admin;
	return data;
}

export async function logout() {
	const { data } = await client.post("/logout");
	localStorage.removeItem("token");
	localStorage.removeItem("isSuperAdmin");
	return data;
}
