import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import type { PartsStyleObject } from "@chakra-ui/theme-tools";

const baseStyle: PartsStyleObject<typeof parts> = {
	dialog: { borderRadius: 0 },
	header: {
		py: 7,
		pos: "relative",
		color: "gray.400",
		_after: {
			content: `""`,
			pos: "absolute",
			w: "18%",
			h: "1px",
			bottom: "22%",
			insetStart: 6,
			bgColor: "gray.100",
		},
	},
	closeButton: {
		top: 4,
		left: 4,
		zIndex: "10",
		borderRadius: "full",
		borderWidth: "1px",
		borderColor: "gray.400",
	},
	footer: {
		py: 7,
	},
};

const Modal = {
	baseStyle,
};
export default Modal;
