import { normalize } from "normalizr";
import { membershipEntity } from "./schemas";
import { client } from ".";

// defining normalize return type manually as normalize has bad typescript support
// general types used here are defined in ./src/types.d.ts file
type Entities = {
	memberships: Record<number, Membership>;
	committees: Record<string, Committee>;
};
type Normalized = {
	entities: Entities;
	result: { data: number };
};
type NormalizedList = {
	entities: Partial<Entities>;
	result: {
		data: number[];
		meta: Meta;
	};
};

type FetchPageArg = { page: number; status?: number };
export async function fetchPage(params: FetchPageArg) {
	const { data } = await client.get("/memberships", { params });
	return normalize(data, { data: [membershipEntity] }) as NormalizedList;
}

export async function show(id: number) {
	const { data } = await client.get(`/memberships/${id}`);
	return normalize(data, { data: membershipEntity }) as Normalized;
}

export async function add(arg: StoreMembership) {
	const { data } = await client.post("/memberships", arg);
	return normalize(data, { data: membershipEntity }) as Normalized;
}

type UpdateArg = { id: number; item: StoreMembership };
export async function update({ id, item }: UpdateArg) {
	const { data } = await client.put(`/memberships/${id}`, item);
	return normalize(data, { data: membershipEntity }) as Normalized;
}

export async function del(id: number) {
	const { data } = await client.delete(`/memberships/${id}`);
	return data;
}

type UpdateStatusArg = { id: number; status: Status };
export async function updateStatus({ id, status }: UpdateStatusArg) {
	const { data } = await client.post(`/memberships/${id}/update-status`, {
		status,
	});
	return data;
}
