import {
	createAsyncThunk,
	createSlice,
	isFulfilled,
	isPending,
	createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "..";
import { fetchPage, add, update, del } from "../../apis/goals.api";
import { sortedIndex } from "../../utils/helpers";
import { isRejectedAction } from "../../utils/redux";

export const handleLoadGoals = createAsyncThunk("goals/all", fetchPage, {
	condition: (page, { getState }) => getState().goals.currentPage !== page,
});

export const handleDelGoal = createAsyncThunk("goals/del", (item: Goal) =>
	del(item.id)
);

export const handleAddGoal = createAsyncThunk("goals/add", add);
export const handleUpdateGoal = createAsyncThunk("goals/update", update);

const slice = createSlice({
	name: "goals",
	initialState: {
		total: 0,
		pagesCount: 1,
		currentPage: null as number | null,
		status: "idle" as LoadingStatus,
		ids: [] as number[],
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoadGoals.pending, (state) => {
				state.status = "pending";
				state.ids = []; // rest ids
			})
			.addCase(handleLoadGoals.fulfilled, (state, { payload }) => {
				state.status = "succeeded";
				state.total = payload.result.meta.total;
				state.pagesCount = payload.result.meta.last_page;
				state.currentPage = payload.result.meta.current_page;
				state.ids = payload.result.data;
			})
			.addCase(handleAddGoal.fulfilled, (state, { payload }) => {
				state.ids.unshift(payload.result.data);
				state.total += 1;
			})
			.addCase(handleDelGoal.pending, (state, { meta: { arg } }) => {
				state.ids.splice(state.ids.indexOf(arg.id), 1); // remove target item from ids array
				state.total -= 1;
			})
			.addCase(handleDelGoal.rejected, (state, { meta: { arg } }) => {
				state.ids.splice(sortedIndex(state.ids, arg.id), 0, arg.id); // put target back to ids array (preserve sorting)
				state.total += 1;
			})
			.addMatcher(isFulfilled(handleAddGoal, handleUpdateGoal), (state) => {
				state.status = "succeeded";
			})
			.addMatcher(isPending(handleAddGoal, handleUpdateGoal), (state) => {
				state.status = "mutating";
			})
			.addMatcher(isRejectedAction("goals/"), (state) => {
				state.status = "failed";
			});
	},
});

export const selectGoals = createSelector(
	(state: RootState) => state.entities.goals,
	(state: RootState) => state.goals.ids,
	(entity, ids) => ids.map((id) => entity[id])
);

export default slice.reducer;
