import { useCallback, useEffect, useMemo, useState } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { useToast } from "@chakra-ui/react";
import {
	useDispatch,
	useSelector,
	TypedUseSelectorHook,
	shallowEqual,
} from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import uniqueId from "lodash/uniqueId";
import qs, { ParsedQuery } from "query-string";
import { uploadImg } from "../apis/media.api";
import { AppDispatch, RootState } from "../redux";
import range from "lodash/range";

export function useQuery() {
	const { search } = useLocation();
	return useMemo(() => qs.parse(search), [search]);
}

export function useAddQuery() {
	const query = useQuery();
	return useCallback(
		(newQuery: ParsedQuery<string | number>) => {
			return qs.stringify({ ...query, ...newQuery });
		},
		[query]
	);
}

export function useRemoveQuery() {
	const query = useQuery();
	return useCallback(
		(key: string) => {
			delete query[key];
			return qs.stringify(query);
		},
		[query]
	);
}

export function useCurrentPage(defaultVal = 1) {
	const query = useQuery();
	return query.page ? parseInt(query.page as string) : defaultVal;
}

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useShallowEqSelector: typeof useAppSelector = (selector) =>
	useAppSelector(selector, shallowEqual);

type UploadStatus = "pending" | "failed" | "succeded";
export type UploadItem = {
	uid: string;
	status: UploadStatus;
	type: string;
	url?: string;
	name?: string;
	preview?: string;
};

function formatDefaultVal(val?: string | string[]): UploadItem[] {
	if (!val) return [];
	if (typeof val === "string") val = [val];
	return val.map((url) => ({
		uid: uniqueId(),
		status: "succeded",
		type: url.slice(-url.indexOf(".")),
		url,
	}));
}

type Args = DropzoneOptions & {
	onChange: (items: UploadItem[]) => void;
	defaultValue?: string | string[]; // if defaultValue is an array, make sure its value doesn't change across renders
};
export function useFileUploader({ onChange, defaultValue, ...rest }: Args) {
	const [fileList, setFileList] = useState<UploadItem[]>(
		formatDefaultVal(defaultValue)
	);
	const toast = useToast();

	function onUploadFailure(uid: string) {
		setFileList((prev) => {
			const clone = prev.slice();
			const file = clone.find((file) => file.uid === uid);
			if (file) file.status = "failed";
			return clone;
		});
	}

	function onUploadSuccess(uid: string, url: string) {
		setFileList((prev) => {
			const clone = prev.slice();
			const file = clone.find((file) => file.uid === uid);
			if (file) {
				file.status = "succeded";
				file.url = url;
			}
			return clone;
		});
	}
	function manageUploading(uid: string, file: File) {
		uploadImg(
			file,
			file.type.includes("image")
				? "image"
				: file.type.includes("video")
				? "video"
				: "document"
		)
			.then((res) => {
				onUploadSuccess(uid, res.path);
			})
			.catch(() => {
				onUploadFailure(uid);
			});
	}

	function handleDrop(accepted: File[]) {
		const { maxFiles, multiple } = rest;
		if (multiple && maxFiles && maxFiles < fileList.length + accepted.length) {
			return toast({
				status: "error",
				description: `لا يمكن إضافة اكتر من ${maxFiles} ملفات`,
			});
		}

		const files: UploadItem[] = [];
		accepted.forEach((file) => {
			const uid = uniqueId();
			files.push({
				uid: uid,
				type: file.type,
				status: "pending",
				preview: file.type.includes("image/")
					? URL.createObjectURL(file)
					: undefined,
			});
			manageUploading(uid, file);
		});

		setFileList((prev) => (multiple ? [...prev, ...files] : files.slice(-1)));
	}

	function handleDelete(uid: string) {
		setFileList((prev) => prev.filter((file) => file.uid !== uid));
	}

	const { getRootProps, getInputProps } = useDropzone({
		onDrop: handleDrop,
		...rest,
	});

	useEffect(() => {
		onChange(fileList);
		return function () {
			// Make sure to revoke the data uris to avoid memory leaks
			fileList.forEach(({ preview }) => {
				if (preview) URL.revokeObjectURL(preview);
			});
		};
	}, [fileList]); // eslint-disable-line

	return { getRootProps, getInputProps, handleDelete, files: fileList };
}

export function useFilePreview(url: string) {
	const [preview, setPreview] = useState<string | null>(null);

	useEffect(() => {
		if (url) {
			const img = new Image();
			img.src = url;
			img.onload = () => setPreview(url);
		}
	}, [url]);

	return preview;
}

export function usePagination(count: number) {
	const history = useHistory();
	const pages = useMemo(() => range(1, count + 1), [count]);
	const currPage = useCurrentPage();
	const addQuery = useAddQuery();

	function nextPage() {
		if (currPage > 1) {
			const search = addQuery({ page: currPage - 1 });
			history.push({ search });
		}
	}
	function prevPage() {
		if (currPage < pages[pages.length - 1]) {
			const search = addQuery({ page: currPage + 1 });
			history.push({ search });
		}
	}

	return { nextPage, prevPage, pages };
}
