import { normalize } from "normalizr";
import { volunteerEntity } from "./schemas";
import { client } from ".";

// defining normalize return type manually as normalize has bad typescript support
// general types used here are defined in ./src/types.d.ts file
type Normalized = {
	entities: { volunteers: Record<number, Volunteer> };
	result: { data: number };
};
type NormalizedList = {
	entities: { volunteers?: Record<number, Volunteer> };
	result: {
		data: number[];
		meta: Meta;
	};
};

export async function fetchPage(page: number) {
	const { data } = await client.get(`/volunteers?page=${page}`);
	return normalize(data, { data: [volunteerEntity] }) as NormalizedList;
}

export async function show(id: number) {
	const { data } = await client.get(`/volunteers/${id}`);
	return normalize(data, { data: volunteerEntity }) as Normalized;
}

export async function del(id: number) {
	const { data } = await client.delete(`/volunteers/${id}`);
	return data;
}

type UpdateStatusArg = { id: number; status: Status };
export async function updateStatus({ id, status }: UpdateStatusArg) {
	const { data } = await client.post(`/volunteers/${id}/update-status`, {
		status,
	});
	return data;
}
