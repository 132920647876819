import {
	createAsyncThunk,
	createSlice,
	isFulfilled,
	isPending,
	createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "..";
import {
	fetchPage,
	add,
	update,
	del,
	delGalleryImg,
} from "../../apis/projects.api";
import { sortedIndex } from "../../utils/helpers";
import { isRejectedAction } from "../../utils/redux";

export const handleLoadProjects = createAsyncThunk("projects/all", fetchPage, {
	condition: (page, { getState }) => getState().projects.currentPage !== page,
});

export const handleDelProject = createAsyncThunk("projects/del", (item: Project) =>
	del(item.id)
);
export const handleAddProject = createAsyncThunk("projects/add", add);

type UpdateArg = { id: number; item: StoreProject; delImages?: number[] };
export const handleUpdateProject = createAsyncThunk(
	"projects/update",
	async ({ id, item, delImages }: UpdateArg) => {
		// handle destroying deleted gallery images first (if any)
		if (delImages) {
			const promises = delImages.map((id) => delGalleryImg(id));
			await Promise.all(promises);
		}
		return update({ id, item });
	}
);

const slice = createSlice({
	name: "projects",
	initialState: {
		total: 0,
		pagesCount: 1,
		currentPage: null as number | null,
		status: "idle" as LoadingStatus,
		ids: [] as number[],
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoadProjects.pending, (state) => {
				state.status = "pending";
				state.ids = []; // rest data
			})
			.addCase(handleLoadProjects.fulfilled, (state, { payload }) => {
				state.status = "succeeded";
				state.total = payload.result.meta.total;
				state.pagesCount = payload.result.meta.last_page;
				state.currentPage = payload.result.meta.current_page;
				state.ids = payload.result.data;
			})
			.addCase(handleAddProject.fulfilled, (state, { payload }) => {
				state.ids.unshift(payload.result.data);
				state.total += 1;
			})
			.addCase(handleDelProject.pending, (state, { meta: { arg } }) => {
				state.ids.splice(state.ids.indexOf(arg.id), 1); // remove target item from ids array
				state.total -= 1;
			})
			.addCase(handleDelProject.rejected, (state, { meta: { arg } }) => {
				state.ids.splice(sortedIndex(state.ids, arg.id), 0, arg.id); // put target back to ids array (preserve sorting)
				state.total += 1;
			})
			.addMatcher(isFulfilled(handleAddProject, handleUpdateProject), (state) => {
				state.status = "succeeded";
			})
			.addMatcher(isPending(handleAddProject, handleUpdateProject), (state) => {
				state.status = "mutating";
			})
			.addMatcher(isRejectedAction("projects/"), (state) => {
				state.status = "failed";
			});
	},
});

export const selectProjects = createSelector(
	(state: RootState) => state.entities.projects,
	(state: RootState) => state.projects.ids,
	(entity, ids) => ids.map((id) => entity[id])
);

export default slice.reducer;
