import {
	createAsyncThunk,
	createSlice,
	isFulfilled,
	isPending,
	createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "..";
import { fetchPage, add, update, del } from "../../apis/directors.api";
import { sortedIndex } from "../../utils/helpers";
import { isRejectedAction } from "../../utils/redux";

export const handleLoadDirectors = createAsyncThunk(
	"directors/all",
	fetchPage,
	{
		condition: (page, { getState }) =>
			getState().directors.currentPage !== page,
	}
);

export const handleDelDirector = createAsyncThunk(
	"directors/del",
	(item: Director) => del(item.id)
);

export const handleAddDirector = createAsyncThunk("directors/add", add);
export const handleUpdateDirector = createAsyncThunk(
	"directors/update",
	update
);

const slice = createSlice({
	name: "directors",
	initialState: {
		total: 0,
		pagesCount: 1,
		currentPage: null as number | null,
		status: "idle" as LoadingStatus,
		ids: [] as number[],
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoadDirectors.pending, (state) => {
				state.status = "pending";
				state.ids = []; // rest ids;
			})
			.addCase(handleLoadDirectors.fulfilled, (state, { payload }) => {
				state.status = "succeeded";
				state.total = payload.result.meta.total;
				state.pagesCount = payload.result.meta.last_page;
				state.currentPage = payload.result.meta.current_page;
				state.ids = payload.result.data;
			})
			.addCase(handleAddDirector.fulfilled, (state, { payload }) => {
				state.ids.unshift(payload.result.data);
				state.total += 1;
			})
			.addCase(handleDelDirector.pending, (state, { meta: { arg } }) => {
				state.ids.splice(state.ids.indexOf(arg.id), 1); // remove target item from ids array
				state.total -= 1;
			})
			.addCase(handleDelDirector.rejected, (state, { meta: { arg } }) => {
				state.ids.splice(sortedIndex(state.ids, arg.id), 0, arg.id); // put target back to ids array (preserve sorting)
				state.total += 1;
			})
			.addMatcher(
				isFulfilled(handleAddDirector, handleUpdateDirector),
				(state) => {
					state.status = "succeeded";
				}
			)
			.addMatcher(
				isPending(handleAddDirector, handleUpdateDirector),
				(state) => {
					state.status = "mutating";
				}
			)
			.addMatcher(isRejectedAction("directors/"), (state) => {
				state.status = "failed";
			});
	},
});

export const selectDirectors = createSelector(
	(state: RootState) => state.entities.directors,
	(state: RootState) => state.directors.ids,
	(entity, ids) => ids.map((id) => entity[id])
);

export default slice.reducer;
