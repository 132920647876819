import { createAsyncThunk, createSlice, isFulfilled } from "@reduxjs/toolkit";
import { show, update } from "../../apis/settings.api";
import { isRejectedAction } from "../../utils/redux";

export const handleShowSettings = createAsyncThunk("settings/show", show, {
	condition: (page, { getState }) => getState().settings.data === null,
});

export const handleUpdateSettings = createAsyncThunk("settings/update", update);

const slice = createSlice({
	name: "settings",
	initialState: {
		data: null as SettingsData | null,
		status: "idle" as LoadingStatus,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(handleShowSettings.pending, (state) => {
				state.status = "pending";
			})
			.addCase(handleUpdateSettings.pending, (state) => {
				state.status = "mutating";
			})
			.addMatcher(
				isFulfilled(handleShowSettings, handleUpdateSettings),
				(state, { payload }) => {
					state.status = "succeeded";
					state.data = payload.data;
				}
			)
			.addMatcher(isRejectedAction("settings/"), (state) => {
				state.status = "failed";
			});
	},
});

export default slice.reducer;
