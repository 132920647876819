import {
	createAsyncThunk,
	createSlice,
	isFulfilled,
	isPending,
	createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "..";
import { fetchPage, add, update, del } from "../../apis/villages.api";
import { sortedIndex } from "../../utils/helpers";
import { isRejectedAction } from "../../utils/redux";

export const handleLoadVillages = createAsyncThunk("villages/all", fetchPage, {
	condition: (arg, { getState }) => {
		const { currentPage, filterBy } = getState().villages;
		return currentPage !== arg.page || filterBy !== arg.government_id;
	},
});

export const handleDelVillage = createAsyncThunk(
	"villages/del",
	(item: Village) => del(item.id)
);

export const handleAddVillage = createAsyncThunk("villages/add", add);
export const handleUpdateVillage = createAsyncThunk("villages/update", update);

const slice = createSlice({
	name: "villages",
	initialState: {
		total: 0,
		pagesCount: 1,
		currentPage: null as number | null,
		filterBy: undefined as number | undefined,
		status: "idle" as LoadingStatus,
		ids: [] as number[],
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoadVillages.pending, (state) => {
				state.status = "pending";
				state.ids = []; // rest ids
			})
			.addCase(handleLoadVillages.fulfilled, (state, { payload, meta }) => {
				state.status = "succeeded";
				state.total = payload.result.meta.total;
				state.pagesCount = payload.result.meta.last_page;
				state.currentPage = payload.result.meta.current_page;
				state.filterBy = meta.arg.government_id;
				state.ids = payload.result.data;
			})
			.addCase(handleAddVillage.fulfilled, (state, { payload }) => {
				state.ids.unshift(payload.result.data);
				state.total += 1;
			})
			.addCase(handleDelVillage.pending, (state, { meta: { arg } }) => {
				state.ids.splice(state.ids.indexOf(arg.id), 1); // remove target item from ids array
				state.total -= 1;
			})
			.addCase(handleDelVillage.rejected, (state, { meta: { arg } }) => {
				state.ids.splice(sortedIndex(state.ids, arg.id), 0, arg.id); // put target back to ids array (preserve sorting)
				state.total += 1;
			})
			.addMatcher(
				isFulfilled(handleAddVillage, handleUpdateVillage),
				(state) => {
					state.status = "succeeded";
				}
			)
			.addMatcher(isPending(handleAddVillage, handleUpdateVillage), (state) => {
				state.status = "mutating";
			})
			.addMatcher(isRejectedAction("villages/"), (state) => {
				state.status = "failed";
			});
	},
});

export const selectAllVillages = createSelector(
	(state: RootState) => state.entities.villages,
	(entity) => Object.values(entity)
);

export const selectVillages = createSelector(
	(state: RootState) => state.entities.villages,
	(state: RootState) => state.villages.ids,
	(entity, ids) => ids.map((id) => entity[id])
);

export default slice.reducer;
