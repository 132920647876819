import {
	createAsyncThunk,
	createSlice,
	isFulfilled,
	isPending,
	createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "..";
import { fetchPage, add, update, del } from "../../apis/govFilesTypes.api";
import { sortedIndex } from "../../utils/helpers";
import { isRejectedAction } from "../../utils/redux";

export const handleLoadGovFileTypes = createAsyncThunk(
	"govFileTypes/all",
	fetchPage,
	{
		condition: (page, { getState }) =>
			getState().govFileTypes.currentPage !== page,
	}
);

export const handleDelGovFileType = createAsyncThunk(
	"govFileTypes/del",
	(item: GovFileType) => del(item.id)
);

export const handleAddGovFileType = createAsyncThunk("govFileTypes/add", add);
export const handleUpdateGovFileType = createAsyncThunk("govFileTypes/update", update);

const slice = createSlice({
	name: "govFileTypes",
	initialState: {
		total: 0,
		pagesCount: 1,
		currentPage: null as number | null,
		status: "idle" as LoadingStatus,
		ids: [] as number[],
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoadGovFileTypes.pending, (state) => {
				state.status = "pending";
				state.ids = []; // rest data
			})
			.addCase(handleLoadGovFileTypes.fulfilled, (state, { payload }) => {
				state.status = "succeeded";
				state.total = payload.result.meta.total;
				state.pagesCount = payload.result.meta.last_page;
				state.currentPage = payload.result.meta.current_page;
				state.ids = payload.result.data;
			})
			.addCase(handleAddGovFileType.fulfilled, (state, { payload }) => {
				state.ids.unshift(payload.result.data);
				state.total += 1;
			})
			.addCase(handleDelGovFileType.pending, (state, { meta: { arg } }) => {
				state.ids.splice(state.ids.indexOf(arg.id), 1); // remove target item from ids array
				state.total -= 1;
			})
			.addCase(handleDelGovFileType.rejected, (state, { meta: { arg } }) => {
				state.ids.splice(sortedIndex(state.ids, arg.id), 0, arg.id); // put target back to ids array (preserve sorting)
				state.total += 1;
			})
			.addMatcher(isFulfilled(handleAddGovFileType, handleUpdateGovFileType), (state) => {
				state.status = "succeeded";
			})
			.addMatcher(isPending(handleAddGovFileType, handleUpdateGovFileType), (state) => {
				state.status = "mutating";
			})
			.addMatcher(isRejectedAction("govFileTypes/"), (state) => {
				state.status = "failed";
			});
	},
});

export const selectAllGovs = createSelector(
	(state: RootState) => state.entities.govFileTypes,
	(entity) => Object.values(entity)
);

export const selectGovFileTypes = createSelector(
	(state: RootState) => state.entities.govFileTypes,
	(state: RootState) => state.govFileTypes.ids,
	(entity, ids) => ids.map((id) => entity[id])
);

export default slice.reducer;
