import {
	createAsyncThunk,
	createSlice,
	createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "..";
import { fetchPage } from "../../apis/permissions.api";
export const handleLoadPerms = createAsyncThunk("permissions/all", fetchPage, {
	condition: (page, { getState }) =>
		getState().permissions.currentPage !== page,
});

const slice = createSlice({
	name: "permissions",
	initialState: {
		total: 0,
		pagesCount: 1,
		currentPage: null as number | null,
		status: "idle" as LoadingStatus,
		ids: [] as number[],
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoadPerms.pending, (state) => {
				state.status = "pending";
				state.ids = []; // reset data
			})
			.addCase(handleLoadPerms.fulfilled, (state, { payload }) => {
				state.status = "succeeded";
				// state.total = payload.result.meta.total;
				// state.pagesCount = payload.result.meta.last_page;
				// state.currentPage = payload.result.meta.current_page;
				state.ids = payload.result.data;
			})
			.addCase(handleLoadPerms.rejected, (state) => {
				state.status = "failed";
			});
	},
});

export const selectAllPermissions = createSelector(
	(state: RootState) => state.entities.permissions,
	(entity) => Object.values(entity)
);

export const selectPermissions = createSelector(
	(state: RootState) => state.entities.permissions,
	(state: RootState) => state.permissions.ids,
	(entity, ids) => ids.map((id) => entity[id])
);

export default slice.reducer;
