import { normalize } from "normalizr";
import { messageEntity } from "./schemas";
import { client } from ".";

// defining normalize return type manually as normalize has bad typescript support
// general types used here are defined in ./src/types.d.ts file
type Normalized = {
	entities: { messages: Record<number, Message> };
	result: { data: number };
};
type NormalizedList = {
	entities: { messages?: Record<number, Message> };
	result: {
		data: number[];
		meta: Meta;
	};
};

export async function fetchPage(page: number) {
	const { data } = await client.get(`/support-messages?page=${page}`);
	return normalize(data, { data: [messageEntity] }) as NormalizedList;
}

export async function show(id: number) {
	const { data } = await client.get(`/support-messages/${id}`);
	return normalize(data, { data: messageEntity }) as Normalized;
}

export async function del(id: number) {
	const { data } = await client.delete(`/support-messages/${id}`);
	return data;
}
