import { Route } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import Login from "./pages/Login";
import Dashboard from "./Dashboard";

function App() {
	return (
		<div className="App">
			<Route path="/login">
				<Login />
			</Route>
			<PrivateRoute path="/">
				<Dashboard />
			</PrivateRoute>
		</div>
	);
}

export default App;
