import { FC } from "react";
import { useForm } from "react-hook-form";
import {
	Button,
	Checkbox,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	Box,
	VStack,
	Image,
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../utils/hooks";
import { handleLogin } from "../redux/slices/auth.slice";
import { emailPattern } from "../utils/patterns";
import validationMsgs from "../data/validation";
// images
import logo from "../images/baity-logo.png";

type LoginFormProps = {};
const LoginForm: FC<LoginFormProps> = (props) => {
	const dispatch = useAppDispatch();
	const status = useAppSelector((state) => state.auth.status);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<LoginData>();

	function onSubmit(data: LoginData) {
		dispatch(handleLogin(data));
	}

	return (
		<Box
			as="form"
			noValidate
			onSubmit={handleSubmit(onSubmit)}
			p={{ base: 6, md: 12, lg: 16 }}
			bgColor="white"
		>
			<Image
				src={logo}
				alt="Baity Logo"
				htmlWidth="309"
				htmlHeight="130"
				mb="20"
			/>
			<VStack as="fieldset" align="start" spacing="4">
				<Box
					as="legend"
					fontSize="2xl"
					fontWeight="bold"
					color="gray.500"
					mb="4"
				>
					لوحة التحكم
				</Box>
				<FormControl isInvalid={!!errors.email}>
					<FormLabel>البريد الإلكتروني</FormLabel>
					<Box boxShadow="md">
						<Input
							{...register("email", {
								required: validationMsgs["required"],
								pattern: {
									value: emailPattern,
									message: validationMsgs["invalidEmail"],
								},
							})}
						/>
					</Box>
					<FormErrorMessage>{errors.email?.message}</FormErrorMessage>
				</FormControl>
				<FormControl isInvalid={!!errors.password}>
					<FormLabel>كلمة المرور</FormLabel>
					<Box boxShadow="md">
						<Input
							type="password"
							{...register("password", {
								required: validationMsgs.required,
								minLength: {
									value: 6,
									message: validationMsgs.shortPw,
								},
							})}
						/>
					</Box>
					<FormErrorMessage>{errors.password?.message}</FormErrorMessage>
				</FormControl>
				<Checkbox>تذكرني</Checkbox>
				<Box boxShadow="brand" alignSelf="end">
					<Button size="lg" type="submit" isLoading={status === "pending"}>
						تسجيل الدخول
					</Button>
				</Box>
			</VStack>
		</Box>
	);
};

export default LoginForm;
