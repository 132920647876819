import Button from "./button";
import Container from "./container";
import Input from "./input";
import Textarea from "./textarea";
import Badge from "./badge";
import Table from "./table";
import Select from "./select";
import Modal from "./modal";
import Checkbox from "./checkbox";
import Radio from "./radio";

const components = {
	Button,
	Container,
	Input,
	Badge,
	Table,
	Select,
	Textarea,
	Modal,
	Checkbox,
	Radio,
};

export default components;
