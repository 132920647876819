import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { login, logout as logoutApi } from "../../apis/auth.api";
import { isRejectedAction } from "../../utils/redux";

export const handleLogin = createAsyncThunk("auth/login", login);

const slice = createSlice({
	name: "auth",
	initialState: {
		status: "idle" as LoadingStatus,
		token: localStorage.getItem("token"),
		isSuperAdmin: localStorage.isSuperAdmin === "true",
	},
	reducers: {
		logout(state) {
			state.token = null;
			state.isSuperAdmin = false;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(handleLogin.fulfilled, (state, { payload }) => {
				state.status = "succeeded";
				state.token = payload.token;
				state.isSuperAdmin = payload.is_super_admin;
			})
			.addCase(handleLogin.pending, (state) => {
				state.status = "pending";
			})
			.addCase(handleLogin.rejected, (state) => {
				state.status = "failed";
			})
			.addMatcher(isRejectedAction(), (state, { error }) => {
				// handle unauthorized requests error in all actions
				if (error.message === "401") {
					state.token = null;
					state.isSuperAdmin = false;
				}
			});
	},
});

const { logout } = slice.actions;

export function handleLogout() {
	return (dispatch: any) => {
		logoutApi();
		dispatch(logout());
	};
}

export default slice.reducer;
