import { normalize } from "normalizr";
import { newsEntity } from "./schemas";
import { client } from ".";

// defining normalize return type manually as normalize has bad typescript support
// general types used here are defined in ./src/types.d.ts file
type Normalized = {
	entities: { news: Record<number, News> };
	result: { data: number };
};
type NormalizedList = {
	entities: { news?: Record<number, News> };
	result: {
		data: number[];
		meta: Meta;
	};
};

export async function fetchPage(params?: {
	page: number;
	is_donation?: number;
}) {
	const { data } = await client.get(`/news`, { params });
	return normalize(data, { data: [newsEntity] }) as NormalizedList;
}

export async function show(id: number) {
	const { data } = await client.get(`/news/${id}`);
	return normalize(data, { data: newsEntity }) as Normalized;
}

export async function add(arg: StoreNews) {
	const { data } = await client.post("/news", arg);
	return normalize(data, { data: newsEntity }) as Normalized;
}

type UpdateArg = { id: number; item: StoreNews };
export async function update({ id, item }: UpdateArg) {
	const { data } = await client.put(`/news/${id}`, item);
	return normalize(data, { data: newsEntity }) as Normalized;
}

export async function del(id: number) {
	const { data } = await client.delete(`/news/${id}`);
	return data;
}

export async function delGalleryImg(id: number) {
	const { data } = await client.delete(`/news-images/${id}`);
	return data;
}

type UpdateStatusArg = { id: number; status: Status };
export async function updateStatus({ id, status }: UpdateStatusArg) {
	const { data } = await client.post(`/news/${id}/update-status`, {
		status,
	});
	return data;
}
