// credit: https://stackoverflow.com/a/21822316/10272966
export function sortedIndex(array: number[], value: number) {
    let low = 0;
		let high = array.length;
    while (low < high) {
        var mid = (low + high) >>> 1;
        if (array[mid] > value) low = mid + 1;
        else high = mid;
    }
    return low;
}
