// using chakra convention for customizing its theme
// ref: https://chakra-ui.com/docs/theming/customize-theme#scaling-out-your-project
import {
	extendTheme,
	theme as base,
	ThemeOverride,
	withDefaultColorScheme,
} from "@chakra-ui/react";
import "intl-tel-input/build/css/intlTelInput.css";
import components from "./components";

const overrides: ThemeOverride = {
	direction: "rtl",
	styles: {
		global: {
			body: { bgColor: "gray.50" },
			":root": { fontSize: "14px", direction: "rtl" },
			".page": {
				mx: 6,
				my: 4,
				bgColor: "white",
				minH: "calc(100vh - var(--chakra-sizes-8))",
			},
			".iti": { w: "full" },
			".iti--allow-dropdown .iti__flag-container": {
				insetStart: 0,
				insetEnd: "auto",
			},
			".iti--allow-dropdown input[type=tel]": {
				direction: "ltr",
				textAlign: "right",
				pl: "4",
				pr: "14",
			},
			".iti__arrow": {
				me: "0",
				ms: "6px",
			},
			".iti__country": { direction: "ltr" },
			".iti__country-list": { textAlign: "start", shadow: "md" },
			".iti-mobile .iti--container": {
				zIndex: 9999,
				insetInline: "10px",
				w: "calc(100% - 20px)",
			},
		},
	},
	shadows: {
		md: "0px 3px 20px #C2C2C22E",
		lg: "0px 0px 45px #C2C2C23B",
		brand: `-2px 2px 0 rgba(214, 158, 46, 0.24)`,
	},
	fonts: {
		heading: `Droid Arabic Kufi, ${base.fonts.heading}`,
		body: `Droid Arabic Kufi, ${base.fonts.body}`,
	},
	colors: {
		gray: {
			"50": "#f5f5f5",
			"100": "#ebebebe0",
			"400": "#909090",
		},
		golden: {
			"50": "#fef9f4",
			"100": "#fcf4e9",
			"200": "#f8e3c9",
			"300": "#f3d1a8",
			"400": "#ebaf67",
			"500": "#e28d26",
			"600": "#cb7f22",
			"700": "#aa6a1d",
			"800": "#885517",
			"900": "#6f4513",
		},
		brown: {
			"50": "#fbf9f6",
			"100": "#f6f3ee",
			"200": "#e9e2d4",
			"300": "#dcd0b9",
			"400": "#c2ac85",
			"500": "#A88951",
			"600": "#977b49",
			"700": "#7e673d",
			"800": "#655231",
			"900": "#524328",
		},
		brand: {
			"50": "#fbf3f3",
			"100": "#f7e7e6",
			"200": "#ecc3c1",
			"300": "#e19f9b",
			"400": "#ca5750",
			"500": "#B30F05",
			"600": "#a10e05",
			"700": "#860b04",
			"800": "#6b0903",
			"900": "#580702",
		},
	},
	components,
};

export default extendTheme(
	overrides,
	withDefaultColorScheme({
		colorScheme: "brand",
		components: ["Button", "Input",],
	}),
	withDefaultColorScheme({
		colorScheme: "golden",
		components: ["Radio", "Checkbox", "Switch"],
	})
);
