import {
	createAsyncThunk,
	createSlice,
	isFulfilled,
	isPending,
	createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "..";
import { fetchPage, show, add, update, del, updateStatus } from "../../apis/jobs.api";
import { sortedIndex } from "../../utils/helpers";
import { isRejectedAction } from "../../utils/redux";

export const handleLoadJobs = createAsyncThunk("jobs/all", fetchPage, {
	condition: (page, { getState }) => getState().jobs.currentPage !== page,
});

export const handleShowJob = createAsyncThunk("jobs/show", show, {
	condition: (id, { getState }) => !getState().entities.jobs[id],
});

export const handleDelJob = createAsyncThunk("jobs/del", (item: Job) =>
	del(item.id)
);

export const handleAddJob = createAsyncThunk("jobs/add", add);
export const handleUpdateJob = createAsyncThunk("jobs/update", update);
export const handleUpdateJobStatus = createAsyncThunk(
	"jobs/update-status",
	updateStatus
);

const slice = createSlice({
	name: "jobs",
	initialState: {
		total: 0,
		pagesCount: 1,
		currentPage: null as number | null,
		status: "idle" as LoadingStatus,
		ids: [] as number[],
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoadJobs.pending, (state) => {
				state.status = "pending";
				state.ids = []; // rest data
			})
			.addCase(handleLoadJobs.fulfilled, (state, { payload }) => {
				state.status = "succeeded";
				state.total = payload.result.meta.total;
				state.pagesCount = payload.result.meta.last_page;
				state.currentPage = payload.result.meta.current_page;
				state.ids = payload.result.data;
			})
			.addCase(handleAddJob.fulfilled, (state, { payload }) => {
				state.ids.unshift(payload.result.data);
				state.total += 1;
			})
			.addCase(handleDelJob.pending, (state, { meta: { arg } }) => {
				state.ids.splice(state.ids.indexOf(arg.id), 1); // remove target item from ids array
				state.total -= 1;
			})
			.addCase(handleDelJob.rejected, (state, { meta: { arg } }) => {
				state.ids.splice(sortedIndex(state.ids, arg.id), 0, arg.id); // put target back to ids array (preserve sorting)
				state.total += 1;
			})
			.addMatcher(isFulfilled(handleAddJob, handleUpdateJob), (state) => {
				state.status = "succeeded";
			})
			.addMatcher(isPending(handleAddJob, handleUpdateJob), (state) => {
				state.status = "mutating";
			})
			.addMatcher(isRejectedAction("jobs/"), (state) => {
				state.status = "failed";
			});
	},
});

export const selectJobs = createSelector(
	(state: RootState) => state.entities.jobs,
	(state: RootState) => state.jobs.ids,
	(entity, ids) => ids.map((id) => entity[id])
);

export default slice.reducer;
