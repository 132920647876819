import { tableAnatomy as parts } from "@chakra-ui/anatomy";
import { ComponentStyleConfig } from "@chakra-ui/theme";
import type { PartsStyleObject } from "@chakra-ui/theme-tools";

const columnBorderStyle = {
	_notLast: {
		borderLeftWidth: "1px",
		borderColor: "gray.100",
	},
};
const variantCustom: PartsStyleObject<typeof parts> = {
	thead: {
		th: {
			px: "6",
			py: "5",
			whiteSpace: "nowrap",
			borderColor: `brown.500`,
			bgColor: `brown.500`,
			fontSize: "sm",
			color: "white",
		},
	},
	th: columnBorderStyle,
	td: columnBorderStyle,
	tbody: {
		tr: {
			"&:nth-of-type(even)": {
				"th, td": {
					borderTopWidth: "1px",
					bgColor: "gray.50",
				},
			},
		},
	},
};

const Table: ComponentStyleConfig = {
	variants: {
		custom: variantCustom,
	},
};

export default Table;
