import { ComponentType, lazy } from "react";
import Invoices from "./pages/Invoices";
const Admins = lazy(() => import("./pages/Admins"));
const Roles = lazy(() => import("./pages/Roles"));
const News = lazy(() => import("./pages/News"));
const Donations = lazy(() => import("./pages/Donations"));
const Beneficiaries = lazy(() => import("./pages/Beneficiaries"));
const Partners = lazy(() => import("./pages/Partners"));
const Governments = lazy(() => import("./pages/Governments"));
const Villages = lazy(() => import("./pages/Villages"));
const MembershipCats = lazy(() => import("./pages/MembershipCats"));
const Memberships = lazy(() => import("./pages/Memberships"));
const Volunteers = lazy(() => import("./pages/Volunteers"));
const Directors = lazy(() => import("./pages/Directors"));
const Jobs = lazy(() => import("./pages/Jobs"));
const Initiatives = lazy(() => import("./pages/Initiatives"));
const Projects = lazy(() => import("./pages/Projects"));
const Reports = lazy(() => import("./pages/Reports"));
const GovFileTypes = lazy(() => import("./pages/GovFileTypes"));
const GovFiles = lazy(() => import("./pages/GovFiles"));
const Committees = lazy(() => import("./pages/Committees"));
const Settings = lazy(() => import("./pages/Settings"));
const Messages = lazy(() => import("./pages/Messages"));
const Goals = lazy(() => import("./pages/Goals"));
const Principles = lazy(() => import("./pages/Principles"));

type NavRoute = {
	to: string;
	children: string;
	exact?: boolean;
};
export const navRoutes: NavRoute[] = [
	{
		to: "/beneficiaries",
		children: "المستفيدين",
	},
	{
		to: "/membership-cats",
		children: "أنوع العضوية",
	},
	{
		to: "/memberships",
		children: "العضويات",
	},
	{
		to: "/volunteers",
		children: "المتطوعين",
	},
	{
		to: "/news",
		children: "الأخبار",
	},
	{
		to: "/donations",
		children: "حالات التبرعات",
	},
	{
		to: "/partners",
		children: "الشركاء",
	},
	{
		to: "/directors",
		children: "أعضاء مجلس الإدارة",
	},
	{
		to: "/initiatives",
		children: "المبادرات",
	},
	{
		to: "/invoices",
		children: "الفواتير",
	},
	{
		to: "/jobs",
		children: "الوظائف",
	},
	{
		to: "/projects",
		children: "المشاريع",
	},
	{
		to: "/committees",
		children: "اللجان",
	},
	{
		to: "/principles",
		children: "القيم",
	},
	{
		to: "/goals",
		children: "الأهداف",
	},
	{
		to: "/reports",
		children: "التقارير",
	},
	{
		to: "/governance-file-types",
		children: "تصنيفات ملف الحوكمة",
	},
	{
		to: "/governance-files",
		children: "ملفات الحوكمة",
	},
	{
		to: "/governments",
		children: "المحافظات",
	},
	{
		to: "/villages",
		children: "القرى",
	},
	{
		to: "/admins",
		children: "مشرفي الموقع",
	},
	{
		to: "/roles",
		children: "الأدوار",
	},
	{
		to: "/messages",
		children: "اتصل بنا",
	},
	{
		to: "/settings",
		children: "الإعدادات",
	},
];

type Route = {
	path: string;
	Component: ComponentType;
	exact?: boolean;
};
const routes: Route[] = [
	{
		path: "/admins",
		Component: Admins,
	},
	{
		path: "/roles",
		Component: Roles,
	},
	{
		path: "/news",
		Component: News,
	},
	{
		path: "/donations",
		Component: Donations,
	},
	{
		path: "/beneficiaries",
		Component: Beneficiaries,
	},
	{
		path: "/partners",
		Component: Partners,
	},
	{
		path: "/governments",
		Component: Governments,
	},
	{
		path: "/villages",
		Component: Villages,
	},
	{
		path: "/membership-cats",
		Component: MembershipCats,
	},
	{
		path: "/memberships",
		Component: Memberships,
	},
	{
		path: "/volunteers",
		Component: Volunteers,
	},
	{
		path: "/directors",
		Component: Directors,
	},
	{
		path: "/jobs",
		Component: Jobs,
	},
	{
		path: "/initiatives",
		Component: Initiatives,
	},
	{
		path: "/invoices",
		Component: Invoices,
	},
	{
		path: "/projects",
		Component: Projects,
	},
	{
		path: "/reports",
		Component: Reports,
	},
	{
		path: "/governance-file-types",
		Component: GovFileTypes,
	},
	{
		path: "/governance-files",
		Component: GovFiles,
	},
	{
		path: "/committees",
		Component: Committees,
	},
	{
		path: "/settings",
		Component: Settings,
	},
	{
		path: "/messages",
		Component: Messages,
	},
	{
		path: "/goals",
		Component: Goals,
	},
	{
		path: "/principles",
		Component: Principles,
	},
];

export default routes;
