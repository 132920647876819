import {
	createAsyncThunk,
	createSlice,
	createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "..";
import { fetchPage } from "../../apis/invoices.api";
import { isRejectedAction } from "../../utils/redux";

export const handleLoadInvoices = createAsyncThunk("invoices/all", fetchPage, {
	condition: (params, { getState }) =>
		getState().jobs.currentPage !== params?.page,
});

const slice = createSlice({
	name: "invoices",
	initialState: {
		total: 0,
		pagesCount: 1,
		currentPage: null as number | null,
		filterBy: undefined as string | undefined,
		status: "idle" as LoadingStatus,
		ids: [] as number[],
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoadInvoices.pending, (state) => {
				state.status = "pending";
				state.ids = []; // rest ids
			})
			.addCase(handleLoadInvoices.fulfilled, (state, { payload, meta }) => {
				state.status = "succeeded";
				state.total = payload.result.meta.total;
				state.pagesCount = payload.result.meta.last_page;
				state.currentPage = payload.result.meta.current_page;
				state.filterBy = meta?.arg?.type || meta?.arg?.is_paid;
				state.ids = payload.result.data;
			})
			.addMatcher(isRejectedAction("invoices/"), (state) => {
				state.status = "failed";
			});
	},
});

export const selectInvoices = createSelector(
	(state: RootState) => state.entities.invoices,
	(state: RootState) => state.invoices.ids,
	(entity, ids) => ids.map((id) => entity[id])
);

export default slice.reducer;
