import { normalize } from "normalizr";
import { committeeEntity } from "./schemas";
import { client } from ".";

// defining normalize return type manually as normalize has bad typescript support
// general types used here are defined in ./src/types.d.ts file
type Normalized = {
	entities: { committees: Record<string, Committee>; };
	result: { data: number };
};
type NormalizedList = {
	entities: { committees?: Record<string, Committee>; };
	result: {
		data: number[];
		meta: Meta;
	};
};

export async function fetchPage(page: number) {
	const { data } = await client.get(`/committees?page=${page}`);
	return normalize(data, { data: [committeeEntity] }) as NormalizedList;
}

export async function show(id: number) {
	const { data } = await client.get(`/committees/${id}`);
	return normalize(data, { data: committeeEntity }) as Normalized;
}

export async function add(arg: StoreCommittee) {
	const { data } = await client.post("/committees", arg);
	return normalize(data, { data: committeeEntity }) as Normalized;
}

type UpdateArg = { id: number; item: StoreCommittee };
export async function update({ id, item }: UpdateArg) {
	const { data } = await client.put(`/committees/${id}`, item);
	return normalize(data, { data: committeeEntity }) as Normalized;
}

export async function del(id: number) {
	const { data } = await client.delete(`/committees/${id}`);
	return data;
}
