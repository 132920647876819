import { normalize } from "normalizr";
import { permEntity } from "./schemas";
import { client } from ".";

// defining normalize return type manually as normalize has bad typescript support
// general types used here are defined in ./src/types.d.ts file
type NormalizedList = {
	entities: { permissions?: Record<number, Permission> };
	result: {
		data: number[];
		meta: Meta;
	};
};

export async function fetchPage(page: number) {
	const { data } = await client.get(`/permissions`);
	return normalize(data, { data: [permEntity] }) as NormalizedList;
}
