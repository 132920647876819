import { normalize } from "normalizr";
import { commMemberEntity } from "./schemas";
import { client } from ".";

// defining normalize return type manually as normalize has bad typescript support
// general types used here are defined in ./src/types.d.ts file
type Entities = {
	commMembers: Record<number, CommMember>;
	committees: Record<number, Committee>;
};
type Normalized = {
	entities: Entities;
	result: { data: number };
};
type NormalizedList = {
	entities: Partial<Entities>;
	result: {
		data: number[];
		meta: Meta;
	};
};

type AllArg = { page: number; committee_id: number };
export async function fetchPage({ page, committee_id }: AllArg) {
	const { data } = await client.get("/committee-members", {
		params: { page, committee_id },
	});
	return normalize(data, { data: [commMemberEntity] }) as NormalizedList;
}

export async function show(id: number) {
	const { data } = await client.get(`/committee-members/${id}`);
	return normalize(data, { data: commMemberEntity }) as Normalized;
}

export async function add(arg: StoreCommMember) {
	const { data } = await client.post("/committee-members", arg);
	return normalize(data, { data: commMemberEntity }) as Normalized;
}

type UpdateArg = { id: number; item: StoreCommMember };
export async function update({ id, item }: UpdateArg) {
	const { data } = await client.put(`/committee-members/${id}`, item);
	return normalize(data, { data: commMemberEntity }) as Normalized;
}

export async function del(id: number) {
	const { data } = await client.delete(`/committee-members/${id}`);
	return data;
}
