import { normalize } from "normalizr";
import { villageEntity } from "./schemas";
import { client } from ".";

// defining normalize return type manually as normalize has bad typescript support
// general types used here are defined in ./src/types.d.ts file
type Entities = {
	governments: Record<number, Government>;
	villages: Record<number, Village>;
};
type Normalized = {
	entities: Entities;
	result: { data: number };
};
type NormalizedList = {
	entities: Partial<Entities>;
	result: {
		data: number[];
		meta: Meta;
	};
};

type AllArg = { page: number; government_id?: number };
export async function fetchPage({ page, government_id }: AllArg) {
	const { data } = await client.get("/villages", {
		params: { page, government_id },
	});
	return normalize(data, { data: [villageEntity] }) as NormalizedList;
}

export async function show(id: number) {
	const { data } = await client.get(`/villages/${id}`);
	return normalize(data, { data: villageEntity }) as Normalized;
}

export async function add(arg: StoreVillage) {
	const { data } = await client.post("/villages", arg);
	return normalize(data, { data: villageEntity }) as Normalized;
}

type UpdateArg = { id: number, item: StoreVillage };
export async function update({ id, item }: UpdateArg) {
	const { data } = await client.put(`/villages/${id}`, item);
	return normalize(data, { data: villageEntity }) as Normalized;
}

export async function del(id: number) {
	const { data } = await client.delete(`/villages/${id}`);
	return data;
}
