import { createSlice } from "@reduxjs/toolkit";
import merge from "lodash/merge";
import { isFulfilledAction } from "../../utils/redux";

const slice = createSlice({
	name: "entities",
	initialState: {
		admins: {} as Record<number, Admin>,
		roles: {} as Record<number, Role>,
		permissions: {} as Record<number, Permission>,
		villages: {} as Record<number, Village>,
		governments: {} as Record<number, Government>,
		beneficiaries: {} as Record<number, Beneficiary>,
		partners: {} as Record<number, Partner>,
		invoices: {} as Record<number, Invoice>,
		news: {} as Record<number, News>,
		committees: {} as Record<number, Committee>,
		membershipCats: {} as Record<number, MembershipCat>,
		memberships: {} as Record<number, Membership>,
		volunteers: {} as Record<number, Volunteer>,
		directors: {} as Record<number, Director>,
		jobs: {} as Record<number, Job>,
		messages: {} as Record<number, Message>,
		initiatives: {} as Record<number, Initiative>,
		projects: {} as Record<number, Project>,
		reports: {} as Record<number, Report>,
		goals: {} as Record<number, Goal>,
		principles: {} as Record<number, Principle>,
		govFiles: {} as Record<number, GovFile>,
		jobApps: {} as Record<number, JobApp>,
		commMembers: {} as Record<number, CommMember>,
		govFileTypes: {} as Record<number, GovFileType>,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addMatcher(isFulfilledAction(), (state, { payload }: any) => {
				if (payload.entities) merge(state, payload.entities);
			})
			.addMatcher(
				({ type }) => type.endsWith("/del/fulfilled"),
				(state, { meta, type }: any) => {
					const eName = type.slice(0, type.indexOf("/")) as keyof typeof state;
					if (state[eName]) delete state[eName][meta.arg.id];
				}
			)
			.addMatcher(
				({ type }) => type.endsWith("/update-status/pending"),
				(state: any, { meta, type }: any) => {
					const eName = type.slice(0, type.indexOf("/")) as keyof typeof state;
					if (state[eName]) state[eName][meta.arg.id].status = meta.arg.status;
				}
			)
			.addMatcher(
				({ type }) => type.endsWith("/update-status/rejected"),
				(state: any, { meta, type }: any) => {
					const eName = type.slice(0, type.indexOf("/")) as keyof typeof state;
					const oldStatus = meta.arg.status === 2 ? 1 : 2;
					if (state[eName]) state[eName][meta.arg.id].status = oldStatus;
				}
			);
	},
});

export default slice.reducer;
