import { normalize } from "normalizr";
import { jobEntity } from "./schemas";
import { client } from ".";

// defining normalize return type manually as normalize has bad typescript support
// general types used here are defined in ./src/types.d.ts file
type Entities = { jobs: Record<number, Job> };
type Normalized = {
	entities: Entities;
	result: { data: number };
};
type NormalizedList = {
	entities: Partial<Entities>;
	result: {
		data: number[];
		meta: Meta;
	};
};

export async function fetchPage(page: number) {
	const { data } = await client.get(`/jobs?page=${page}`);
	return normalize(data, { data: [jobEntity] }) as NormalizedList;
}

export async function show(id: number) {
	const { data } = await client.get(`/jobs/${id}`);
	return normalize(data, { data: jobEntity }) as Normalized;
}

export async function add(arg: StoreJob) {
	const { data } = await client.post("/jobs", arg);
	return normalize(data, { data: jobEntity }) as Normalized;
}

type UpdateArg = { id: number; item: StoreJob };
export async function update({ id, item }: UpdateArg) {
	const { data } = await client.put(`/jobs/${id}`, item);
	return normalize(data, { data: jobEntity }) as Normalized;
}

export async function del(id: number) {
	const { data } = await client.delete(`/jobs/${id}`);
	return data;
}

type UpdateStatusArg = { id: number; status: Status };
export async function updateStatus({ id, status }: UpdateStatusArg) {
	const { data } = await client.post(`/jobs/${id}/update-status`, {
		status,
	});
	return data;
}
