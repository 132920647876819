import { normalize } from "normalizr";
import { adminEntity } from "./schemas";
import { client } from ".";

// defining normalize return type manually as normalize has bad typescript support
// general types used here are defined in ./src/types.d.ts file
type Normalized = {
	entities: {
		admins: Record<number, Admin>;
		roles: Record<string, Role>;
	};
	result: { data: number };
};
type NormalizedList = {
	entities: { admins?: Record<number, Admin> };
	result: {
		data: number[];
		meta: Meta;
	};
};

export async function fetchPage(page: number) {
	const { data } = await client.get(`/admins?page=${page}`);
	return normalize(data, { data: [adminEntity] }) as NormalizedList;
}

export async function show(id: number) {
	const { data } = await client.get(`/admins/${id}`);
	return normalize(data, { data: adminEntity }) as Normalized;
}

export async function add(arg: StoreAdmin) {
	const { data } = await client.post("/admins", arg);
	return normalize(data, { data: adminEntity }) as Normalized;
}

type UpdateArg = { id: number; item: StoreAdmin };
export async function update({ id, item }: UpdateArg) {
	const { data } = await client.put(`/admins/${id}`, item);
	return normalize(data, { data: adminEntity }) as Normalized;
}

export async function del(id: number) {
	const { data } = await client.delete(`/admins/${id}`);
	return data;
}
