import {
	createAsyncThunk,
	createSlice,
	isFulfilled,
	isPending,
	createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "..";
import { fetchPage, add, update, del } from "../../apis/partners.api";
import { sortedIndex } from "../../utils/helpers";
import { isRejectedAction } from "../../utils/redux";

export const handleLoadPartners = createAsyncThunk("partners/all", fetchPage, {
	condition: (page, { getState }) => getState().partners.currentPage !== page,
});

export const handleDelPartner = createAsyncThunk(
	"partners/del",
	(item: Partner) => del(item.id)
);

export const handleAddPartner = createAsyncThunk("partners/add", add);
export const handleUpdatePartner = createAsyncThunk("partners/update", update);

const slice = createSlice({
	name: "partners",
	initialState: {
		total: 0,
		pagesCount: 1,
		currentPage: null as number | null,
		status: "idle" as LoadingStatus,
		ids: [] as number[],
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoadPartners.pending, (state) => {
				state.status = "pending";
				state.ids = []; // rest ids
			})
			.addCase(handleLoadPartners.fulfilled, (state, { payload }) => {
				state.status = "succeeded";
				state.total = payload.result.meta.total;
				state.pagesCount = payload.result.meta.last_page;
				state.currentPage = payload.result.meta.current_page;
				state.ids = payload.result.data;
			})
			.addCase(handleAddPartner.fulfilled, (state, { payload }) => {
				state.ids.unshift(payload.result.data);
				state.total += 1;
			})
			.addCase(handleDelPartner.pending, (state, { meta: { arg } }) => {
				state.ids.splice(state.ids.indexOf(arg.id), 1); // remove target item from ids array
				state.total -= 1;
			})
			.addCase(handleDelPartner.rejected, (state, { meta: { arg } }) => {
				state.ids.splice(sortedIndex(state.ids, arg.id), 0, arg.id); // put target back to ids array (preserve sorting)
				state.total += 1;
			})
			.addMatcher(
				isFulfilled(handleAddPartner, handleUpdatePartner),
				(state) => {
					state.status = "succeeded";
				}
			)
			.addMatcher(isPending(handleAddPartner, handleUpdatePartner), (state) => {
				state.status = "mutating";
			})
			.addMatcher(isRejectedAction("partners/"), (state) => {
				state.status = "failed";
			});
	},
});

export const selectPartners = createSelector(
	(state: RootState) => state.entities.partners,
	(state: RootState) => state.partners.ids,
	(entity, ids) => ids.map((id) => entity[id])
);

export default slice.reducer;
