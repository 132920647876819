import {
	createAsyncThunk,
	createSlice,
	isFulfilled,
	isPending,
	createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "..";
import { fetchPage, add, update, del } from "../../apis/governments.api";
import { sortedIndex } from "../../utils/helpers";
import { isRejectedAction } from "../../utils/redux";

export const handleLoadGovs = createAsyncThunk("governments/all", fetchPage, {
	condition: (page, { getState }) =>
		getState().governments.currentPage !== page,
});

export const handleDelGov = createAsyncThunk(
	"governments/del",
	(item: Government) => del(item.id)
);

export const handleAddGov = createAsyncThunk("governments/add", add);
export const handleUpdateGov = createAsyncThunk("governments/update", update);

const slice = createSlice({
	name: "governments",
	initialState: {
		total: 0,
		pagesCount: 1,
		currentPage: null as number | null,
		status: "idle" as LoadingStatus,
		ids: [] as number[],
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoadGovs.pending, (state) => {
				state.status = "pending";
				state.ids = []; // rest data
			})
			.addCase(handleLoadGovs.fulfilled, (state, { payload }) => {
				state.status = "succeeded";
				state.total = payload.result.meta.total;
				state.pagesCount = payload.result.meta.last_page;
				state.currentPage = payload.result.meta.current_page;
				state.ids = payload.result.data;
			})
			.addCase(handleAddGov.fulfilled, (state, { payload }) => {
				state.ids.unshift(payload.result.data);
				state.total += 1;
			})
			.addCase(handleDelGov.pending, (state, { meta: { arg } }) => {
				state.ids.splice(state.ids.indexOf(arg.id), 1); // remove target item from ids array
				state.total -= 1;
			})
			.addCase(handleDelGov.rejected, (state, { meta: { arg } }) => {
				state.ids.splice(sortedIndex(state.ids, arg.id), 0, arg.id); // put target back to ids array (preserve sorting)
				state.total += 1;
			})
			.addMatcher(isFulfilled(handleAddGov, handleUpdateGov), (state) => {
				state.status = "succeeded";
			})
			.addMatcher(isPending(handleAddGov, handleUpdateGov), (state) => {
				state.status = "mutating";
			})
			.addMatcher(isRejectedAction("governments/"), (state) => {
				state.status = "failed";
			});
	},
});

export const selectAllGovs = createSelector(
	(state: RootState) => state.entities.governments,
	(entity) => Object.values(entity)
);

export const selectGovernments = createSelector(
	(state: RootState) => state.entities.governments,
	(state: RootState) => state.governments.ids,
	(entity, ids) => ids.map((id) => entity[id])
);

export default slice.reducer;
