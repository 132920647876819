import { useEffect, useState } from "react";
import {
	Box,
	ButtonGroup,
	Stack,
	Table,
	TableCaption,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Container,
	Skeleton,
	Select,
	Text,
	Badge,
} from "@chakra-ui/react";
import range from "lodash/range";
import {
	useAddQuery,
	useAppDispatch,
	useAppSelector,
	useCurrentPage,
	useRemoveQuery,
} from "../utils/hooks";
import {
	handleLoadInvoices,
	selectInvoices,
} from "../redux/slices/invoices.slice";
import Pagination from "../components/Pagination";
import { formatDate } from "../utils/date";
import NavButton from "../components/NavButton";
import { Pdf, SheetIcon } from "../components/Icons";
import { HStack, IconButton } from "@chakra-ui/react";
import { useQuery } from "../utils/hooks";
import { useHistory } from "react-router-dom";
import { download } from "../apis/invoices.api";

const loadingRows = range(2);

function Invoices() {
	const { type, is_paid } = useQuery() as {
		is_paid?: string;
		page?: string;
		type?: string;
	};

	const history = useHistory();
	const addQuery = useAddQuery();
	const removeQuery = useRemoveQuery();

	const currentPage = useCurrentPage();
	const dispatch = useAppDispatch();
	const all = useAppSelector(selectInvoices);
	const { status, pagesCount } = useAppSelector((state) => state.invoices);
	useEffect(() => {
		dispatch(
			handleLoadInvoices({
				page: currentPage,
				type,
				is_paid,
			})
		);
	}, [currentPage, type, is_paid]); // eslint-disable-line

	return (
		<Box className="page">
			<Box as="header" shadow="md">
				<Stack
					as={Container}
					py="5"
					direction={{ base: "column", md: "row" }}
					justify="space-between"
				>
					<ButtonGroup flexDir={{ base: "column", sm: "row" }}>
						<NavButton
							leftIcon={<SheetIcon />}
							isActive
							mb={{ base: "2", sm: "0" }}
						>
							استعراض الفواتير
						</NavButton>
					</ButtonGroup>
					<HStack>
						<Text fontWeight="bold" color="brand.500">
							التصفية:
						</Text>
						<Select
							size="md"
							width={{ base: "full", sm: "auto" }}
							value={is_paid ?? ""}
							onChange={({ target: { value } }) => {
								const search = value
									? addQuery({ is_paid: value, page: 1 })
									: addQuery({ is_paid: null, page: 1 });
								history.push({ search });
							}}
							minW="40"
							bgColor="gray.50"
							borderRadius="full"
						>
							<option value="">حالة الدفع</option>
							<option value="1">مدفوعة</option>
							<option value="0">غير مدفوعة</option>
						</Select>
						<Select
							size="md"
							width={{ base: "full", sm: "auto" }}
							value={type ?? ""}
							onChange={({ target: { value } }) => {
								const search = value
									? addQuery({ type: value, page: 1 })
									: addQuery({ page: 1, type: null });

								history.push({ search });
							}}
							minW="40"
							bgColor="gray.50"
							borderRadius="full"
						>
							<option value="">النوع</option>
							<option value="membership">عضوية</option>
							<option value="donation">حالة تبرع</option>
						</Select>
					</HStack>
				</Stack>
			</Box>
			<Container py="5">
				<Box overflowX="auto">
					<Table variant="custom">
						<TableCaption>قائمة الفواتير</TableCaption>
						<Thead>
							<Tr>
								<Th scope="col">#</Th>
								<Th scope="col">تاريخ الإضافة</Th>
								<Th scope="col">تاريخ الدفع</Th>
								<Th scope="col">السعر</Th>
								<Th scope="col">قيمة الخصم</Th>
								<Th scope="col">الضريبة</Th>
								<Th scope="col">السعر النهائي</Th>
								<Th scope="col">النوع</Th>
								<Th scope="col">البريد الالكتروني</Th>
								<Th scope="col">الهاتف</Th>
								<Th scope="col">حالة الدفع</Th>
								<Th scope="col">التفاصيل</Th>
								<Th scope="col">الاجراءات</Th>
							</Tr>
						</Thead>
						<Tbody>
							{status === "pending" &&
								loadingRows.map((row, i) => (
									<Tr key={i}>
										<Td colSpan={6}>
											<Skeleton height="15px" />
										</Td>
									</Tr>
								))}
							{status !== "pending" &&
								all.map((item, i) => (
									<Tr key={item.id}>
										<Td>{i + 1}</Td>
										<Td>{formatDate(item.created_at)}</Td>
										<Td>{item.paid_at ? formatDate(item.paid_at) : "-"}</Td>
										<Td>{item.amount}</Td>
										<Td>{item.discount}</Td>
										<Td>{item.tax}</Td>
										<Td>{item.total}</Td>
										<Td>{item.type}</Td>
										<Td>{item.invoiceable?.email}</Td>
										<Td>{item.invoiceable?.phone}</Td>
										<Td>
											<Badge p="2" colorScheme={item.paid_at ? "green" : "red"}>
												{item.paid_at ? "مدفوعة" : "غير مدفوعة"}
											</Badge>
										</Td>
										<Td
											sx={{
												minWidth: "200px",
											}}
										>
											{getInvoicableDetails(item)}
										</Td>
										<Td>
											<IconButton
												// as={RouterLink}
												// to={`/requests/withdrawals/${item.id}`}
												onClick={() => {
													download(item.id);
												}}
												colorScheme="error"
												variant="unstyled"
												aria-label="pdf-invoice-download"
												fontSize="2.5rem"
												icon={<Pdf />}
											/>
										</Td>
									</Tr>
								))}
						</Tbody>
					</Table>
				</Box>
				<Pagination count={pagesCount} colorScheme="brown" />
			</Container>
		</Box>
	);
}

export default Invoices;

const getInvoicableDetails = (invoice: Invoice) => {
	if (invoice.invoiceable) {
		if (invoice.type === "membership") return invoice.invoiceable.name;
		if (invoice.type === "donation")
			return (invoice.invoiceable as donationInvoiceable).case?.title?.ar;
	}
	return "غير محدد";
};
