import { ComponentStyleConfig } from "@chakra-ui/react";
const Input = require("./input").default;

const Textarea: ComponentStyleConfig = {
	sizes: { lg: Input.sizes.lg.field },
	variants: { outline: Input.variants.outline.field },
	defaultProps: Input.defaultProps,
};

export default Textarea;
