import { ComponentStyleConfig } from "@chakra-ui/react";

const Badge: ComponentStyleConfig = {
	variants: {
		pill: {
			minW: 7,
			h: 7,
			lineHeight: 7,
			borderRadius: "full",
			textAlign: "center",
			bgColor: "gray.100",
			_empty: {
				d: "none"
			}
		},
	},
};

export default Badge;
